<template>
  <div>
    <h1>Harbor Cruise Bookings</h1>

    <div v-if="isTableLoading" class="text-center pt-3">
      <b-spinner label="Loading..." />
    </div>

    <template v-else>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
          :columns="columns"
          :rows="bookingsWithPayments"
          :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
          @on-column-filter="onColumnFilter"
          @on-row-click="clickRow"
      >
        <template slot="table-column" slot-scope="props">
          <span
              v-if="props.column.label === 'Customer'"
              class="d-flex align-items-center"
          >
            <span class="mr-25">
              {{props.column.label}}
            </span>
            <feather-icon
                icon="InfoIcon"
                size="16"
                v-b-tooltip.hover
                class="text-primary"
                title="Click on a row in this column to see customer details"
            />
          </span>
          <span
              v-else-if="props.column.label === 'Status'"
              class="d-flex align-items-center"
          >
            <span class="mr-25">
              {{props.column.label}}
            </span>
            <feather-icon
                icon="InfoIcon"
                size="16"
                v-b-tooltip.hover
                class="text-primary"
                title="Click on a row in this column to see payment history"
            />
          </span>

          <span v-else>
              {{props.column.label}}
          </span>
        </template>
        <template slot="column-filter" slot-scope="{ column, updateFilters }">
          <b-form-datepicker
              v-if="column.filterOptions ? column.filterOptions.customFilter : false"
              locale="en"
              size="sm"
              placeholder=""
              reset-button
              @input="(value) => updateFilters(column, value)"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </template>

        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span
              v-if="props.column.field === 'customer_name'"
              class="text-nowrap"
              @click="fieldCustomerDetails(props.row)"
          >
            {{ props.row.customer_name }}
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'" @click="fieldPaymentHistory(props.row)">
            <b-badge :variant="status[props.row.status].class">
              {{ status[props.row.status].status }}
            </b-badge>
          </span>

          <!-- Column: Survey -->
          <span v-else-if="props.column.field === 'survey'">
            <feather-icon v-if="!props.row.survey" icon="XIcon" size="24" />
            <b-button
                v-else
                variant="primary"
                :to="`/charter-survey/${props.row.id}`"
                size="sm"
            >
              Submitted Answers
            </b-button>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  right
              >
                <template v-slot:button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                    v-if="props.row.status !== 6"
                    v-b-modal.edit-modal
                    @click="openEditModal(props.row)"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                    v-if="props.row.status !== 4 && props.row.status !== 6"
                    v-b-modal.add-payment-modal
                    @click="openAddPaymentModal(props.row)"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Add Payment</span>
                </b-dropdown-item>

                <b-dropdown-item
                    @click="resendBookingEmail(props.row.id)"
                >
                  <feather-icon icon="MailIcon" class="mr-50" />
                  <span>Resend email</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteItem(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-end flex-wrap">
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </template>
      </vue-good-table>
    </template>

    <b-modal
        id="edit-modal"
        :title='"Edit booking details #" + edited.id'
    >
      <b-form>
        <b-form-checkbox v-model="edited.cancelled" switch class="mb-1">
          Cancel this booking?
        </b-form-checkbox>
        <template v-if="!edited.cancelled">
          <b-form-group label="Status" label-for="status">
            <v-select
                id="status-select"
                v-model="edited.status"
                label="status"
                :options="Object.values(statusSelect)"
                :clearable="false"
            />
          </b-form-group>

          <b-form-group label="Boat:" label-for="input-boat">
            <v-select
                :options="boatNamesTripsPictures"
                :items="boatNamesTripsPictures"
                v-model="edited.boatObject"
                item-text="name"
                item-value="id"
                label="name"
                :clearable="false"
                required
                @input="boatChanged"
            />
          </b-form-group>

          <b-form-group label="Charter Package" label-for="text">
            <v-select
                id="charter_package-select"
                v-model="edited.charterPackage"
                label="text"
                :options="packages"
                :clearable="false"
                @input="loadBlockDates"
            />
          </b-form-group>
          <b-form-group label="Start date">
            <b-form-datepicker
                id="start_at-datepicker"
                v-model="edited.date"
                :min="minDate"
                :date-disabled-fn="dateDisabled"
                :disabled="isDatepickerDisabled"
                :placeholder="datepickerPlaceholder"
                locale="en"
                @input="dateChanged"
            />
          </b-form-group>
          <b-form-group
              label="Number of guests"
              label-for="details"
          >
            <v-select
                v-model="edited.details"
                placeholder="Enter guests number"
                :options="guestsOptions"
                :clearable="false"
            />
          </b-form-group>
          <template
              v-if="
              edited.boat === TEASER_ID &&
              (edited.charterPackage === HALF_DAY_TRIP ||
                edited.charterPackage === 'Harbor Cruise')
            "
          >
            <b-form-group label="Choose the time">
              <v-select
                  id="select-time"
                  v-model="edited.time"
                  placeholder="Select time"
                  label="text"
                  :options="times"
                  :clearable="false"
                  :selectable="timesDisabled"
                  :disabled="isDatepickerDisabled"
              />
            </b-form-group>
          </template>
        </template>
      </b-form>
      <template #modal-footer>
        <b-button
            variant="outline-secondary"
            class="float-right"
            @click="$bvModal.hide('edit-modal')"
        >
          Close
        </b-button>
        <b-button
            variant="primary"
            class="float-right"
            @click="editSubmit"
            :disabled="isEditLoading"
        >
          <b-spinner v-if="isEditLoading" label="Spinning" small />
          <span v-else> Save </span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="add-payment-modal"
        :title='"Add payment for #" + payment.bookingId'
    >
      <b-form>
        <b-form-group label="Payment Type" label-for="payment-type">
          <v-select
              id="payment-type"
              class="mb-1"
              v-model="payment.type"
              placeholder="Payment Type"
              label="text"
              :options="paymentTypes"
              :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Payment Method" label-for="payment-method">
          <v-select
              id="payment-method"
              class="mb-1"
              v-model="payment.method"
              placeholder="Payment Method"
              label="text"
              :options="paymentMethods"
              :clearable="false"
          />
        </b-form-group>
        <b-form-group label="Payment Amount">
          <b-form-input
              type="number"
              v-model="payment.amount"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button
            variant="outline-secondary"
            class="float-right"
            @click="$bvModal.hide('add-payment-modal')"
        >
          Close
        </b-button>
        <b-button
            variant="primary"
            class="float-right"
            @click="addPaymentSubmit"
            :disabled="isAddPaymentLoading"
        >
          <b-spinner v-if="isAddPaymentLoading" label="Spinning" small />
          <span v-else>Add Payment</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="payment-history-modal"
        ok-only
        title="Payment History"
        size="xl"
    >
      <div
          v-if="isPaymentHistoryLoading"
          class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else-if="paymentsHistory.length">
        <div text-variant="primary">
          <ul v-if="paymentStatus != 4" :class="['ul_payments']">
            <li><span>{{ 'Total' }}</span> <strong>${{totalPrice}}</strong></li>
            <li><span>{{ 'Paid' }}</span> <strong>${{alreadyPaid}}</strong></li>
            <li><span>{{ 'Refunded' }}</span> <strong>${{refundedAmount}}</strong></li>
            <li v-if="(remainingToPay > 0)"><span>{{ 'Remaining' }}</span> <strong>${{remainingToPay}}</strong></li>
          </ul>
          <ul v-else :class="['ul_payments']">
            <li><span>{{ 'Fully Paid' }}</span> <strong>${{alreadyPaid}}</strong></li>
          </ul>
        </div>
        <div>
          <vue-good-table
              id='paymentsHistoryTable'
              ref='paymentsHistoryTable'

              :columns="paymentColumns"
              :rows="paymentsHistory"
              :pagination-options="{
                enabled: false,
                perPage: pageLength,
              }"

              @on-column-filter="onColumnFilter"
              @on-row-click="clickRowPayments"
              @on-selected-rows-change="paymentRowsSelected"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true,
                selectAllByGroup: false,
              }"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'amount'" @click="">
                <b-form-input
                    type="number"
                    min="1"
                    :max="props.formattedRow['amount']"
                    size="sm"
                    required
                    class="refund_amount_field"
                    v-model="refundAmountShowHide[props.formattedRow['id']]['amount']"
                    v-if="refundAmountShowHide[props.formattedRow['id']] !== undefined && refundAmountShowHide[props.formattedRow['id']]['visible'] =='show'"
                />
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </span>
              <span v-else-if="props.column.field == 'refunds'">
                <div v-for="(refundData, index) in props.formattedRow['refunds']" :key="index">
                  {{refundData['date']}} - ${{refundData['amount']}}
                </div>
              </span>
              <span v-else>
                  {{props.formattedRow[props.column.field]}}
              </span>
            </template>
          </vue-good-table>
        </div>
        <div v-if="bookingLogs!=null && bookingLogs.length>0" class="pt-2">
          <h4>Booking change history</h4>
          <hr />
          <ul :class="['ul_payments']">
            <li v-for="bookingLog in bookingLogs">
              <div><strong>{{bookingLog.date}}</strong></div>

              <div v-if="bookingLog.old_price != undefined && bookingLog.old_price != bookingLog.new_price">Price changed from <i>${{bookingLog.old_price}}</i> to <i>${{bookingLog.new_price}}</i></div>
              <div v-if="bookingLog.old_guests  && bookingLog.old_guests != bookingLog.new_guests">Guests changed from <i>{{bookingLog.old_guests}}</i> to <i>{{bookingLog.new_guests}}</i></div>
              <div v-if="bookingLog.old_boat != undefined && bookingLog.old_boat != bookingLog.new_boat">Boat changed from <i>{{bookingLog.old_boat}}</i> to <i>{{bookingLog.new_boat}}</i></div>
              <div v-if="bookingLog.old_package != undefined && bookingLog.old_package != bookingLog.new_package">Package changed from <i>{{bookingLog.old_package}}</i> to <i>{{bookingLog.new_package}}</i></div>
              <div v-if="bookingLog.old_date_time != undefined && bookingLog.old_date_time != bookingLog.new_date_time">Date changed from <i>{{bookingLog.old_date_time}}</i> to <i>{{bookingLog.new_date_time}}</i></div>
            </li>
          </ul>
        </div>
      </div>
      <h4 v-else class="text-center">
        There are no payments.
      </h4>
      <template #modal-footer>
        <b-button
            variant="primary"
            @click="checkRefundPayments"
            :disabled="refundButtonDisabled"
        >
          Refund
        </b-button>
        <b-button
            variant="outline-secondary"
            class="float-right"
            @click="$bvModal.hide('payment-history-modal')"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal
        id="customer-modal"
        ok-only
        title="Customer's Details"
        cancel-variant="outline-secondary"
    >
      <b-form-group
          label="Name"
          class="capitalize"
      >
        <b-form-input
            v-model="customer.name"
            readonly
        />
      </b-form-group>
      <b-form-group
          label="Email"
          class="capitalize"
      >
        <b-form-input
            v-model="customer.email"
            readonly
        />
      </b-form-group>
      <b-form-group
          label="Phone"
          class="capitalize"
      >
        <b-form-input
            v-model="customer.phone"
            readonly
        />
      </b-form-group>

      <b-form-group
          label="Stripe Id"
          class="capitalize"
      >
        <b-form-input
            v-model="customer.stripe_id"
            readonly
        />
      </b-form-group>

    </b-modal>

    <b-modal
        id="refund-modal"
        ref="refund-modal"
        title="Confirm the refunding"
        ok-title="Refund"
        cancel-variant="outline-secondary"
        @ok="refundPayments"

    >
      <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
      >
        <h5>Are you sure you want to refund selected payments?</h5>

        <div class="pt-2">
          <b-form-checkbox v-model="refundAlsoCancel" switch class="mb-1">
            Also Cancel this booking
          </b-form-checkbox>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  VBModal,
  BForm,
  BFormDatepicker,
  BSpinner,
  BButton,
  BFormCheckbox,
  BTable,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";
import axios from "@/libs/axios";
import { toastSuccess, toastFailure } from "@/libs/toastification";
import {sweetWarning, sweetError} from "@/libs/sweet-alerts";
import {checkReservedDate, isTimeSelectable} from "@/libs/reserved-dates";
import {FULL_DAY_TRIP, THREE_FOURTHS_DAY_TRIP, HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID} from "@/constants/trip-types.enum";

export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BForm,
    vSelect,
    BFormDatepicker,
    BSpinner,
    BButton,
    BFormCheckbox,
    BTable,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      HALF_DAY_TRIP, HARBOR_CRUISE, TEASER_ID, FULL_DAY_TRIP, THREE_FOURTHS_DAY_TRIP,
      packages: null,
      isTableLoading: true,
      isEditLoading: false,
      refundButtonDisabled: true,
      refundAlsoCancel:false,
      searchTerm: "",
      boatFilterOptions:[''],
      paymentColumns:[
        {
          label: "#Id",
          field: "id",
          type: "string",
          thClass: "text-left",
          tdClass: "text-left",
          hidden: true
        },
        {
          label: "Date",
          field: "date",
          type: "string",
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          label: "Available",
          field: "amount",
          type: "numeric",
          thClass: "text-left",
          tdClass: "text-left",
          formatFn: this.cellMoneyFormat,
        },
        {
          label: "Paid",
          field: "paid",
          type: "numeric",
          thClass: "text-left",
          tdClass: "text-left",
          formatFn: this.cellMoneyFormat,
        },
        {
          label: "Method",
          field: "method",
          type: "string",
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          label: "Refunded",
          field: "amount_refunded",
          type: "numeric",
          thClass: "text-left",
          tdClass: "text-left",
          formatFn: this.cellMoneyFormat,
        },
        {
          label: "Refunds",
          field: "refunds",
          thClass: "text-left",
          tdClass: "text-left",
          html: 'true',
        },
      ],
      columns: [
        {
          label: "Charter #",
          field: "id",
          type: "number",
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          label: "Customer",
          field: "customer_name",
          tdClass: "customer-name",
        },
        {
          label: "Boat",
          field: "boat",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: this.returnBoatNames(),
            trigger: "enter",
          },
        },
        {
          label: "Date",
          field: "date",
          width: '140px',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'yyyy-MM-dd',
          filterOptions: {
            customFilter: true
          },
        },
        {
          label: 'Time',
          field: 'time',
        },
        {
          label: "Charter Package",
          field: "charter_package",
          thClass: "text-left",
          tdClass: "text-left",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            trigger: "enter",
            filterDropdownItems: [
              FULL_DAY_TRIP,
              THREE_FOURTHS_DAY_TRIP,
              HALF_DAY_TRIP,
              HARBOR_CRUISE,
            ],
          },
        },
        {
          label: "Status",
          field: "status",
          type: "number",
          tdClass: "payment-status",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: [
              {
                value: 0,
                text: "Deposit Needed",
              },
              {
                value: 2,
                text: "Deposit Paid",
              },
              {
                value: 3,
                text: "Partially Paid",
              },
              {
                value: 4,
                text: "Fully Paid",
              },
              {
                value: 6,
                text: "Complete",
              },
              {
                value: 7,
                text: "Overpaid",
              },
              {
                value: 1,
                text: "Cancelled",
              },
            ],
            trigger: "enter",
          },
        },
        {
          label: "Charter Survey",
          field: "survey",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: [
              {
                value: 0,
                text: "Not completed",
              },
              {
                value: 1,
                text: "Completed",
              },
            ],
            trigger: "enter",
          },
        },
        {
          label: "Action",
          field: "action",
          thClass: "text-right pr-1",
          tdClass: "text-right",
          sortable: false,
        },
      ],
      pageLength: 10,
      status: {
        0: {
          id: 0,
          status: "Deposit Needed",
          class: "light-primary",
        },
        1: {
          id: 1,
          status: "Cancelled",
          class: "light-dark",
        },
        2: {
          id: 2,
          status: "Deposit Paid",
          class: "light-success",
        },
        3: {
          id: 3,
          status: "Partially Paid",
          class: "light-warning",
        },
        4: {
          id: 4,
          status: "Fully Paid",
          class: "light-danger",
        },
        5: {
          status: "Captain Tip",
        },
        6: {
          status: "Complete",
        },
        7: {
          status: "Overpaid",
          class: "danger",
        },
      },
      statusSelect: {
        0: {
          id: 0,
          status: "Deposit Needed",
        },
        2: {
          id: 2,
          status: "Deposit Paid",
        },
        3: {
          id: 3,
          status: "Partially Paid",
        },
        4: {
          id: 4,
          status: "Fully Paid",
        },
        6: {
          id: 6,
          status: "Complete",
        },
        7: {
          id: 7,
          status: "Overpaid",
        },
      },
      edited: {
        id: null,
        boat: null,
        date: null,
        beforeDate: null,
        charterPackage: null,
        beforeCharterPackage:null,
        status: null,
        time: null,
        cancelled: false,
        trip_id: null,
        details: null,
        boatCapacity: null,
        boatObject: null
      },
      minDate: new Date(),
      blockDates: [],
      datepickerPlaceholder: "No date selected",
      isDatepickerDisabled: false,
      isPaymentHistoryLoading: true,
      paymentsHistoryFields: [
        {
          key: 'status',
          formatter: value => {
            return this.status[value].status
          }
        },
        'date',
        'amount',
        'method',
      ],
      paymentsHistory: [],
      alreadyPaid: 0,
      refundedAmount:0,
      totalPrice: 0,
      remainingToPay: 0,
      bookingLogs:null,
      paymentStatus: null,
      customer: {
        name: "",
        email: "",
        phone: "",
        stripe_id: ""
      },
      paymentTypes: [
        {
          text: 'Deposit',
          value: 2,
        },
        {
          text: 'Installment',
          value: 3,
        },
        {
          text: 'Final payment',
          value: 4,
        },
      ],
      payment: {
        bookingId: null,
        type: null,
        amount: 0,
        method: null,
      },
      paymentMethods: [
        'Credit card',
        'Check',
        'Cash',
      ],
      isAddPaymentLoading: false,
      firstApril2022: new Date(2022, 4, 1),
      thisBoatTripNameId:[],
      refundAmountShowHide: []
    };
  },
  computed: {
    ...mapGetters({
      boatNames: "boats/boatNames",
      bookings: "bookings/bookings",
      bookingsWithPayments: "bookings/bookingsWithPayments",
      boatNamesTripsPictures: 'boats/boatNamesTripsPictures',
    }),
    guestsOptions(){
      const options = [];
      for( let i=1; i <= this.edited.boatCapacity; i++ ){
        options.push(i);
      }
      return options;
    },
    charterPackages() {

      const packages = [];

      if( this.boatNamesTripsPictures.length > 0 && this.edited.boat != null ){

        const selectedBoatTripNameId = [];
        let that = this;
        this.boatNamesTripsPictures[this.edited.boat - 1].trips.map(function(trip){

          selectedBoatTripNameId[trip.name] = trip.id;
          if( that.edited.boat == TEASER_ID || (trip.name != HARBOR_CRUISE && trip.name != HALF_DAY_TRIP) ){
            packages.push(trip.name);
          }
        });

        this.thisBoatTripNameId = selectedBoatTripNameId;
      }

      return packages
    },
    times() {
      const timeOptions = [
        {
          value: "06:00:00",
          text: "6 AM",
          selectable: isTimeSelectable("6 AM", this.blockDates, this.edited),
        },
        {
          value: "12:00:00",
          text: "12 PM",
          selectable: isTimeSelectable("12 PM", this.blockDates, this.edited),
        }
      ];

      if (this.edited.charterPackage === HARBOR_CRUISE) {
        timeOptions.push({
          value: "18:00:00",
          text: "6 PM",
          selectable: isTimeSelectable("6 PM", this.blockDates, this.edited),
        });
      }

      return timeOptions;
    },
  },
  watch: {
    'edited.boatObject.id': function () {
      this.loadBlockDates();
    },
    searchTerm(value) {
      this.changeUrl({ search: value });
    },
  },
  async mounted() {

    await this.loadCruiseBookingsWithPayments().then((response)=>{}).catch((error) => {
      toastFailure("Something went wrong.");
    });
    await this.loadBoatNames().catch(() => {
      toastFailure("Something went wrong.");
    });

    await this.loadBoatNamesTripsPictures().then(()=>{

        }
    ).catch((error) => {
      console.log('error', error);
      toastFailure("Something went wrong.");
    });

    this.$set(
        this.columns[2].filterOptions,
        "filterDropdownItems",
        this.boatNames,
        this.boatNamesTripsPictures
    );

    /* eslint-disable-next-line */
    for (const [key, value] of Object.entries(this.$route.query)) {
      switch (key) {
        case "search":
          this.searchTerm = value;
          break;
        case "boat":
          this.$set(this.columns[3].filterOptions, "filterValue", value);
          break;
        case "charter_package":
          this.$set(this.columns[5].filterOptions, "filterValue", value);
          break;
        default:
      }
    }

    this.isTableLoading = false;
  },
  methods: {
    ...mapActions({
      loadBoatNames: "boats/loadBoatNames",
      loadCruiseBookingsWithPayments: "bookings/loadCruiseBookingsWithPayments",
      updateBooking: "bookings/updateBooking",
      resendEmail: "bookings/resendEmail",
      refundBookingPayment: "bookings/refundBookingPayment",
      deleteBooking: "bookings/deleteBooking",
      loadBoatNamesTripsPictures: 'boats/loadBoatNamesTripsPictures',
    }),
    returnBoatNames(){
      return [];
    },
    boatChanged() {

      const selectedBoatTripNameId = [];
      const packagesLocal = [];
      let that = this;
      this.boatNamesTripsPictures[this.edited.boatObject.id - 1].trips.map(function(trip){

        selectedBoatTripNameId[trip.name] = trip.id;
        if( that.edited.boatObject.id == TEASER_ID || (trip.name != HARBOR_CRUISE && trip.name != HALF_DAY_TRIP) ){

          if( trip.inactive === 0 ) {
            packagesLocal.push(trip.name);
          }
        }
      });

      this.thisBoatTripNameId = selectedBoatTripNameId;

      this.packages = packagesLocal;
    },
    async resendBookingEmail(bookingId){

      if (await this.confirmationMessage('Are you sure you want to resend an email for booking #'+bookingId+'?')) {

        this.resendEmail({
          id: bookingId
        }).then(() => {
          toastSuccess("Email successfully sent");
        }).catch((error) => {
          toastFailure("Something went wrong");
        }).finally(() => {

        });
      }

    },
    cellMoneyFormat(value) {
      return '$' + value;
    },
    showError(responseData=null, warning=false, title=null) {

      if( responseData != null && responseData.message != undefined && responseData.message != null ){

        let errorMSG = responseData.message + '</br>';
        if(responseData.errors){

          Object.keys(responseData.errors).map(function(errorKey){
            responseData.errors[errorKey].map(function(value, key){
              errorMSG += value + '</br>';
            });
          });
        }

        if( warning ){
          sweetWarning(title == null ? 'Warning' : title, errorMSG);
        }
        else{
          sweetError(title == null ? 'Error'  : title, errorMSG);
        }
      }
      else{
        toastFailure("Something went wrong.");
      }
    },
    uncheckRow(row){
      setTimeout(function(){
        row.vgtSelected = false;
      }, 100);
    },
    clickRowPayments(params){
      if( params.row.vgtDisabled === true ){

        this.showError({message: '<br>Payment #'+params.row.id+' has no transaction id in database'}, 1, 'Refund must be processed manually');
        this.uncheckRow(params.row);
      }
    },
    paymentRowsSelected(params){

      if( params.selectedRows.length > 0 ){

        let that = this;
        params.selectedRows.map(function(row){

          if( row.vgtDisabled === false ){

            if( that.refundAmountShowHide[row.id] == undefined ){
              that.refundAmountShowHide[row.id] = new Array();
            }

            if( that.refundAmountShowHide[row.id]['visible'] == undefined || that.refundAmountShowHide[row.id]['visible'] == 'hide' ){
              that.refundAmountShowHide[row.id]['visible'] = 'show';
            }
            else{
              //that.refundAmountShowHide[row.id]['visible'] = 'hide';
            }

            that.refundAmountShowHide[row.id]['amount'] = row.amount;
          }
          else{
            that.uncheckRow(row);
          }

        });

        this.refundButtonDisabled = false;
      }
      else{

        let that = this;
        this.$refs['paymentsHistoryTable'].rows.map(function(row){
          if( that.refundAmountShowHide[row.id] == undefined ){
            that.refundAmountShowHide[row.id] = new Array();
          }
          that.refundAmountShowHide[row.id]['visible'] = 'hide';
          that.uncheckRow(row);
        });

        this.refundButtonDisabled = true;
      }
    },
    async checkRefundPayments(){

      this.refundAlsoCancel = false;
      if( this.$refs['paymentsHistoryTable'].selectedRows.length > 0 ){
        this.$root.$emit('bv::show::modal', 'refund-modal');
      }
    },
    refundPayments(){
      this.refundButtonDisabled = true;

      let selectedPayments = [];
      let that = this;
      this.$refs['paymentsHistoryTable'].selectedRows.map(function(selectedPayment){

        selectedPayments.push({
          'id': selectedPayment.id,
          'amount': that.refundAmountShowHide[selectedPayment.id]['amount']
        });
      });

      this.updateBooking({
        id: this.paymentsHistory[0].booking_id,
        status: this.paymentsHistory[0].status,
        cancelled: (this.refundAlsoCancel === true ? 1 : 0),
        refunded: true,
        boat_id: this.edited.boatObject.id
      }).then(() => {
        this.$root.$emit("bv::hide::modal", "payment-history-modal");
        this.refundBookingPayment({
          booking_id: this.paymentsHistory[0].booking_id,
          selected_payments: selectedPayments,
          booking_canceled: this.refundAlsoCancel
        }).then((response) => {
          toastSuccess("Payment successfully refunded");
        }).catch((error) => {
          this.showError(error.response.data);
        });
      }).catch((error) => {
        this.showError(error.response.data);
      }).finally(() => {
        this.isAddPaymentLoading = false;
      });
    },
    openEditModal(row) {
      this.edited.id = row.id;
      this.edited.boat = row.boat_id;
      this.edited.date = row.date;
      this.edited.beforeDate = row.date;
      this.edited.time = row.time;
      this.edited.charterPackage = row.charter_package;
      this.edited.beforeCharterPackage = row.charter_package;
      this.edited.trip_id = row.trip_id;
      this.edited.status = this.statusSelect[row.status];
      this.edited.cancelled = Boolean(row.cancelled);
      this.edited.details = row.details;
      this.edited.boatCapacity = row.boat_capacity;
      this.edited.boatObject = this.boatNamesTripsPictures.find(x => x.id === row.boat_id);

      this.boatChanged();
      this.loadBlockDates(null, true);
    },
    openAddPaymentModal(row) {
      this.payment.bookingId = row.id;
      this.payment.type = this.paymentTypes[0];
      this.payment.amount = 0;
      this.payment.method = 'Credit card';
      this.edited.boatObject = this.boatNamesTripsPictures.find(x => x.id === row.boat_id);
    },
    async deleteItem(id) {
      if (await this.confirmationMessage('Are you sure you want to delete this booking?')) {
        this.deleteBooking(id)
            .then(() => {
              toastSuccess("Booking has been deleted.");
            })
            .catch(() => {
              toastFailure("Something went wrong.");
            });
      }
    },
    async editSubmit() {

      this.isEditLoading = true;

      if (this.edited.cancelled && !(await this.confirmationMessage('Are you sure you want to cancel this booking?'))) {
        this.isEditLoading = false;
        return false;
      }

      if( this.edited.boatCapacity < this.edited.details ){
        this.showError({message: 'Max. guests number is '+this.edited.boatCapacity+' for this boat.'});
        this.isEditLoading = false;
        return false;
      }

      let sendSurvey = false;
      if (!this.edited.cancelled && this.edited.status.id === 6) {
        sendSurvey = await this.confirmationMessage('To send a review request to this customer click YES below. Otherwise, just click NO to complete charter.');
        this.$root.$emit('bv::hide::modal', 'edit-modal');
      }

      this.updateBooking({
        id: this.edited.id,
        date: (this.edited.cancelled === true && this.edited.date === null) ? this.edited.beforeDate : this.edited.date,
        time: this.edited.time ? this.edited.time.value : null,
        charter_package: this.edited.charterPackage,
        status: this.edited.status.id,
        cancelled: this.edited.cancelled,
        details: this.edited.details,
        send_survey: sendSurvey,
        trip_id: this.thisBoatTripNameId[this.edited.charterPackage],
        boat_id: this.edited.boatObject.id
      })
          .then((response) => {

            let emailsError = false;

            if( response.emails_errors ){
              emailsError = {
                message:'Your changes have been updated, but we couldn\'t send the emails because of technical error.'
              };
            }

            if (this.edited.cancelled) {
              if( emailsError ){
                this.showError(emailsError, 1);
              }
              else{
                toastSuccess("This booking has been canceled.");
              }
            }
            else {
              if( emailsError ){
                this.showError(emailsError, 1);
              }
              else{
                toastSuccess("Your changes have been updated.");
              }
            }

            this.$root.$emit("bv::hide::modal", "edit-modal");
          })
          .catch((error) => {
            this.showError(error.response.data);
          })
          .finally(() => {
            this.isEditLoading = false;
          });
    },
    addPaymentSubmit() {
      this.isAddPaymentLoading = true;

      this.updateBooking({
        id: this.payment.bookingId,
        status: this.payment.type.value,
        by_manual_payment: true,
        boat_id: this.edited.boatObject.id
      })
          .then((response) => {
            this.$root.$emit("bv::hide::modal", "add-payment-modal");

            let emailsError = false;

            if( response.emails_errors ){
              emailsError = {
                message:'Your changes have been updated, but we couldn\'t send the emails because of technical error.'
              };
              this.showError(emailsError, 1);
            }

            axios.post('api/write-payment-history', {
              booking_id: this.payment.bookingId,
              amount: this.payment.amount,
              status: this.payment.type.value,
              method: this.payment.method,
            }).then((response) => {
              toastSuccess("Payment successfully added");
            }).catch((error) => {
              this.showError(error.response.data);
            });
          })
          .catch((error) => {
            this.showError(error.response.data);
          })
          .finally(() => {
            this.isAddPaymentLoading = false;
          });
    },
    dateDisabled(date) {
      return checkReservedDate(date, this.blockDates, this.edited, this.boatNamesTripsPictures, this.thisBoatTripNameId);
    },
    onColumnFilter(params) {
      this.changeUrl(params.columnFilters);
    },
    changeUrl(values) {
      const searchParams = new URLSearchParams(window.location.search);

      /* eslint-disable-next-line */
      for (const [key, value] of Object.entries(values)) {
        searchParams.set(key, value);
      }

      window.history.pushState(
          {},
          null,
          `${this.$route.path}?${searchParams.toString()}`
      );
    },
    disableSelectedDate(){
      this.edited.date = null;
      this.datepickerPlaceholder = 'Please select a new date';
    },
    loadBlockDates(event, modalOpened = false) {
      if (this.edited.charterPackage) {
        const editedDate = this.edited.date;
        //this.edited.date = null;
        this.datepickerPlaceholder = "Loading...";
        this.isEditLoading = true;
        this.isDatepickerDisabled = true;
        axios
            .get(
                `api/bookings/block-dates/${this.edited.boatObject.id}/${this.edited.charterPackage}`
            )
            .then((response) => {

              this.datepickerPlaceholder = 'Please choose the date';
              const blockDates = {};

              for (const date of response.data) {

                if( this.edited.date == date.date && this.edited.date != this.edited.beforeDate ){
                  this.disableSelectedDate();
                }

                if (!blockDates[date.date]) {
                  blockDates[date.date] = new Array();
                }

                blockDates[date.date].push(date);
              }

              this.blockDates = blockDates;
              this.isEditLoading = false;
              this.isDatepickerDisabled = false;

              if( this.dateDisabled(this.edited.date)  ){
                this.disableSelectedDate();
              }

              if (modalOpened || !Object.keys(this.blockDates).includes(editedDate)) {
                this.edited.date = editedDate;
              }

            })
            .catch(() => {
              toastFailure("Something went wrong.");
            });
      }
    },
    isSelectable(time) {
      if (this.blockDates[this.edited.date]) {
        /* eslint-disable-next-line */
        return this.blockDates[this.edited.date].includes(time) ? false : true;
      }
      return true;
    },
    timesDisabled(option) {
      return option.selectable;
    },
    clickRow(params) {
      if (params.event.target.classList.contains("payment-status")) {
        this.getPaymentHistory(params.row);
        return;
      }

      if (params.event.target.classList.contains("customer-name")) {
        this.fieldCustomerDetails(params.row);
      }
    },
    fieldPaymentHistory(booking) {
      this.getPaymentHistory(booking)
    },
    getPaymentHistory(booking) {

      let bookingId = booking.id;
      this.$root.$emit('bv::show::modal', 'payment-history-modal');
      this.isPaymentHistoryLoading = true;
      this.bookingLogs = booking.logs;
      axios
          .get(
              `api/payment-history/${bookingId}`
          )
          .then(response => {
            this.paymentsHistory = response.data.data;

            let alreadyPaidTmp = 0;
            let refunded = 0;
            this.paymentsHistory.map(function(payment){
              alreadyPaidTmp += payment.paid;
              refunded += payment.amount_refunded;
            });

            this.alreadyPaid = alreadyPaidTmp;
            this.refundedAmount = refunded;
            this.totalPrice = this.calculatePrice(booking);
            this.remainingToPay = this.totalPrice - (this.alreadyPaid - refunded);
            this.paymentStatus = booking.status;
            this.isPaymentHistoryLoading = false;
            this.edited.boatObject = this.boatNamesTripsPictures.find(x => x.id === booking.boat_id);
          })
          .catch(() => {
            toastFailure("Something went wrong while loading the payments.")
          });
    },
    confirmationMessage(message) {
      return this.$bvModal.msgBoxConfirm(message, {
        title: 'Please Confirm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
      });
    },
    dateChanged() {
      this.edited.time = null;
    },
    fieldCustomerDetails(row) {
      this.customer.name = row.customer_name;
      this.customer.email = row.customer_email;
      this.customer.phone = row.phone;
      this.customer.stripe_id = row.customer_stripe_id;

      this.$root.$emit('bv::show::modal', 'customer-modal');
    },

    calculatePrice(booking) {

      const selectedBoatTripNameId = [];
      const thisBoatPrices = [];
      const thisBoatAdditionalPrices = [];

      this.boatNamesTripsPictures[booking.boat_id - 1].trips.map(function(trip){
        thisBoatPrices[trip.name] = trip.price;
        selectedBoatTripNameId[trip.name] = trip.id;
        thisBoatAdditionalPrices[trip.name] = trip.additional_price == null ? 0 : trip.additional_price;
      });

      if (booking.charter_package === HARBOR_CRUISE) return 800;
      if (booking.boat_id === TEASER_ID && booking.details > 6) {
        const additionalPrice = thisBoatAdditionalPrices[booking.charter_package];
        return thisBoatPrices[booking.charter_package] + additionalPrice * (booking.details - 6);
      }

      return thisBoatPrices[booking.charter_package];

    }

  },
};
</script>

<style>
.ul_payments{
  padding:0px;
  list-style-type: none;
}

.refund_amount_field{
  height: 23px !important;
  width:80px !important;
}
</style>
